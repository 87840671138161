
import React from "react";

export const BackgroundImage = () => {
  return (
    <div 
      className="absolute inset-0 bg-[#F1F0FB]"
      aria-hidden="true"
    >
      <img 
        src="/photo-1549465220-1a8b9238cd48.avif" 
        alt="" 
        className="w-full h-full object-cover opacity-10"
        fetchPriority="high"
        decoding="async"
        loading="eager"
        width="1920"
        height="1080"
      />
    </div>
  );
};
